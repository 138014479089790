//Padding
@mixin py-6{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}
@mixin pt-6{
    padding-top: 4rem !important;
}
@mixin pb-6{
    padding-bottom: 4rem !important;
}
@mixin py-7{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}
@mixin pt-7{
    padding-top: 5rem !important;
}
@mixin pb-7{
    padding-bottom: 5rem !important;
}
@mixin py-8{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}
@mixin pt-8{
    padding-top: 6rem !important;
}
@mixin pb-8{
    padding-bottom: 6rem !important;
}
@mixin px-6{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
}
@mixin ps-6{
    padding-left: 4rem !important;
}
@mixin pe-6{
    padding-right: 4rem !important;
}
@mixin px-7{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
@mixin ps-7{
    padding-left: 5rem !important;
}
@mixin pe-7{
    padding-right: 5rem !important;
}
@mixin px-8{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}
@mixin ps-8{
    padding-left: 6rem !important;
}
@mixin pe-8{
    padding-right: 6rem !important;
}

//Margin
@mixin my-6{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}
@mixin mt-6{
    margin-top: 4rem !important;
}
@mixin mb-6{
    margin-bottom: 4rem !important;
}
@mixin my-7{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}
@mixin mt-7{
    margin-top: 5rem !important;
}
@mixin mb-7{
    margin-bottom: 5rem !important;
}
@mixin my-8{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}
@mixin mt-8{
    margin-top: 6rem !important;
}
@mixin mb-8{
    margin-bottom: 6rem !important;
}
@mixin mx-6{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
}
@mixin ms-6{
    margin-left: 4rem !important;
}
@mixin me-6{
    margin-right: 4rem !important;
}
@mixin mx-7{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}
@mixin ms-7{
    margin-left: 5rem !important;
}
@mixin me-7{
    margin-right: 5rem !important;
}
@mixin mx-8{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
}
@mixin ms-8{
    margin-left: 6rem !important;
}
@mixin me-8{
    margin-right: 6rem !important;
}