// font family
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-primary: 'Poppins', sans-serif;

//font weight size
$font-w-light:300;
$font-w-regular:400;
$font-w-medium:500;
$font-w-semibold:600;
$font-w-bold:700;
$font-w-xbold:800;
$font-w-black:900;

//font size
$font-s-xxllarge:4rem; //64px
$font-s-xllarge2:3.375rem; //54 
$font-s-xllarge:2.625rem; //42px
$font-s-xlarge:2.25rem; //36px 
$font-s-large2: 1.375rem; //22px
$font-s-large3: 2rem; //32px
$font-s-large:1.25rem; //20px
$font-s-medium:1.125rem; //18px
$font-s-regular:1rem; //16px
$font-s-small:0.9375rem; //15px
$font-s-xsmall: 0.875rem; //14px


//colors
$primary: #1A1A1A;
$light-primary: #ffffff;

$first-color: rgba(0, 146, 69, 1); 
