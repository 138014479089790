.cover{
  position: relative;
  .description{
    //position: absolute;
    //@extend .w-100;
    //@extend .h-100;
    @extend .d-flex;
    @extend .align-content-between;
    @extend .flex-wrap;
    @extend .px-md-5;
    padding-top:10%;
    color: $light-primary;
    @extend .text-center;
    position: relative;
    background-image: url(/img/cover.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(lg) {
        padding-top: 0;
    }
    h1{
      font-size: $font-s-xllarge2;
      font-weight: $font-w-semibold;
      @include media-breakpoint-down(lg) {
        font-size: $font-s-xlarge;
        margin-top:50px;
      }
    }
    h2{
      font-size: $font-s-large3;
      font-style: italic;
      //font-weight: $font-w-semibold;
      margin-top:20px;
      @extend .p-3;
      @extend .p-md-0;
      @include media-breakpoint-down(md) {
        margin-top: 0;
        line-height: 1.7;
      }
      @include media-breakpoint-down(lg) {
        font-size: $font-s-large;
      }
    }
    svg{
      max-width: 100%;
    }
    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }
    .btn{
      margin-top: 30px;
    }
  }
  .cit{
    margin-bottom:-35%;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
  }
}


//Network
#network{
  background-image: url(/img/bg1.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-bottom:50px;
  .intro{
    padding: 195px 0 150px 0;
    font-size: $font-s-large2;
    line-height: 1.925rem;
    @include media-breakpoint-down(lg) {
      font-size: $font-s-medium;
      line-height: 1.5rem;
      padding-bottom:70px;
    }
    h3 {
      font-size: $font-s-xlarge;
      font-weight: $font-w-regular;
      //line-height: 2.475rem;
      margin-bottom: 35px;
      @include media-breakpoint-down(lg) {
        font-size: $font-s-large2;
        line-height: 1.8rem;
      }
    }
    .logo {
      text-align: right;
      @extend .align-self-center;
      @include media-breakpoint-down(md) {
        text-align: left;
        margin:40px 0;
      }
    }
  }
  .ico {
    text-align: center;
    img{
      margin: 0 50px 0 0;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .info{
    @extend .d-flex;
    @extend .align-items-stretch;
    .col-md-4{
      border-left: 2px solid $first-color;
      padding: 0 0 0 19px;
      margin-top:56px;
      @include media-breakpoint-up(md) {
        img{display: none;}
      }
      @include media-breakpoint-down(md) {
        border-left: 0;
      }
    }
    .text{
      text-align: left;
      margin:0 50px 0 0;
      line-height: 1.575rem;
      font-size: $font-s-medium;
      @include media-breakpoint-down(md) {
        border-left: 2px solid $first-color;
        padding-left:15px;
        margin-top:30px;
      }
    }
  }
}

//app
#AppSm{
  .prova{
    background-image: url(/img/bg-app.jpg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top:150px;
    @include media-breakpoint-up(md) {
      .smmo {
        margin-top: -10%;
        margin-bottom: -5%;
      }
    }
    .text{
      font-size: $font-s-large2;
      line-height: 1.925rem;
      padding:70px 0;
      @include media-breakpoint-down(lg) {
        font-size: $font-s-medium;
        line-height: 1.5rem;
        padding: 30px;
      }
      p {
        margin-bottom: 26px;
      }
      h3 {
        font-size: $font-s-xlarge;
        font-weight: $font-w-semibold;
        line-height: 2.475rem;
        margin-bottom: 15px;
        @include media-breakpoint-down(lg) {
          font-size: $font-s-large2;
          line-height: 1.8rem;
        }
      }
    }
  }
}

//adNetwork
#adNetwork{
  padding:170px 0;
  text-align: center;
  @include media-breakpoint-down(lg) {padding: 80px 0;}
  img{
    margin-bottom:50px;
  }
  h3 {
    font-size: $font-s-xlarge;
    font-weight: $font-w-semibold;
    line-height: 2.475rem;
    margin-bottom: 26px;
    @include media-breakpoint-down(lg) {
      font-size: $font-s-large2;
      line-height: 1.8rem;
    }
  }
  h4 {
    font-size: $font-s-large3;
    font-weight: $font-w-semibold;
    line-height: 2.1875rem;
    margin-bottom: 26px;

    @include media-breakpoint-down(lg) {
      font-size: $font-s-large;
      line-height: 1.5rem;
    }
  }
}


//chi siamo
#chiSiamo{
  background-image: url(/img/bg-chi-siamo.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding:100px 0 180px 0;
  color:$light-primary;
  font-size:$font-s-medium;
  .col{
    @extend .me-5;
  }
  .tit{
    font-size: $font-s-xlarge;
    font-weight: $font-w-semibold;
    margin-bottom:20px;
  }
  img{
    margin-bottom:15px;
    @include media-breakpoint-down(sm) {
      margin-top:50px;
    }
  }
  .text{
    @extend .pe-5;
    padding-left:18px;
    border-left:2px solid $light-primary;
    /*@include media-breakpoint-up(md) {
      height: calc(100% - (160px));
    }
    @include media-breakpoint-up(sm) {
      height: calc(100% - 90px);
    }*/
  }
}