header{
  &.App-header{
    .header-navbar{
      background:$light-primary;
      &.navbar-light{
        .navbar-brand{
          @include media-breakpoint-down(sm) {
            width: 75%;
            svg{
              width: 100%;
              height: auto
            }
          }
        }
        .navbar-nav{
          @extend .align-items-md-center;
          .nav-link, .nav-item{
            color: $primary;
            .active{
                color: $primary;
                text-decoration: none;
            }
          }
          .nav-item {
            @extend .nav-link;
            a{
              padding-left: 1rem;
              padding-right: 1rem;
              color: $primary;
              cursor: pointer;
              font-weight: $font-w-semibold;
              @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
              }
              &.btn{
                padding: 0.5rem 1.2rem;
                margin-left: 1rem;
                margin-right: 1rem;
                background: radial-gradient(72.45% 210.57% at -7.65% -61.02%, #14496B 0%, #29ABE2 41.33%, #009245 100%);
                @include media-breakpoint-down(md) {
                  margin-left: 0;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  &.smaller{
      .header-navbar{
        backdrop-filter: blur(0px);
        position: fixed;
        top: 0px;
        width: 100%;
        border-bottom: 1px solid $first-color;
        z-index:3000;
      }
    }
  }
}