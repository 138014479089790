body{
  font-size: 16px;
  font-family:$font-primary;
}
img{
  max-width: 100%;
}
.hand-link{
  cursor: pointer;
}
.title-section{
  font-size: $font-s-xllarge2;
  font-weight: $font-w-bold;
  @extend .mb-4;
  @include media-breakpoint-down(lg) {
    font-size: $font-s-xlarge;
  }
  &.colorPrimary{
    font-size: $font-s-xllarge;
    color: $first-color;
    text-transform: none;
    @extend .mb-1;
    @include media-breakpoint-down(lg) {
      font-size: $font-s-large;
    }
  }
}
.ev{
  color: $first-color;
}
.big {
  font-size: $font-s-large;
  font-weight:$font-w-medium;
}
.desc-title{
  @extend .text-center;
  font-size: $font-s-medium;
  font-weight: $font-w-bold;
  color: $first-color;
  @extend .mb-4;
}
.top-claim{
  font-size: $font-s-large;
  @extend .mb-4;
}
.buttonTop{
  position: fixed;
  right: 15px;
  bottom: 30%;
}
hr{
  &.colorPrimary-underline{
    height: 7px;
    width: 97px;
    margin: .5rem 0;
    background-color: $first-color;
    opacity: 1;
    @include media-breakpoint-down(lg) {
      height: 4px;
      width: 70px;
    }
  }
  &.colorLight-underline{
    height: 7px;
    width: 97px;
    margin: .5rem 0;
    background-color: $light-primary;
    opacity: 1;
  
    @include media-breakpoint-down(lg) {
      height: 4px;
      width: 70px;
    }
  }
}
.btn{
  border-radius: 4rem;
  font-weight: $font-w-semibold;
  padding: 0.5rem 1.5rem;
  &.btn-primary, &.btn-primary:hover, &.btn-primary:disabled{
    background:$first-color;
    border:$first-color;
    color: $light-primary !important;
  }
  &.btn-light,.btn-light:hover,&.btn-light:disabled{
    color:$first-color;
  }
}


.col-box{
  @extend .d-flex;
  @extend .flex-column;
  .box{
      flex: 1;
      @extend .mb-5;
      @extend .px-3;
      margin-top: 65px;
      border: 1px solid rgba(224, 234, 237, 1);
      background-color: $light-primary;
      .symbol-box{
          @extend .d-flex;
          @extend .justify-content-center;
          margin-top: -65px;
          @extend .mb-2;
      }
      ul{
        list-style: none;
        @extend .p-0;
        li{
          //font-weight: $font-w-bold;
          font-size: $font-s-xsmall;
          @extend .py-2;
          border-bottom: 1px solid rgba(224, 234, 237, 1);
          @extend .d-flex;
          //@extend .justify-content-between;
          @extend .align-items-center;
          &.title, &:last-child{
            border-bottom: 0px;
          }
          &.title{
            @extend .justify-content-center;
            font-size: $font-s-medium;
            font-weight: $font-w-bold;
            text-align: center;
          }
        }
      }
  }
}