#footer-page{
    @include py-8;
    font-size: $font-s-xsmall;
    a{
      color: $primary;
      text-decoration: none;
      font-weight: $font-w-semibold;
    }
    .powered{
      color:$first-color;
      font-size:$font-s-large;
      font-weight: $font-w-semibold;
    }
    .credits{
      span{
        @extend .d-block;
      }
    }
  .logo{
    margin-bottom:35px;
  }
  .social{
    padding:38px 0 78px 0;
    a{
      margin-right:30px;
      display: inline-block;
    }
  }
}